export const scrollFix = {
  /**
   * on bodyを固定する関数
   * @param {callback} callback - bodyを固定した後に実行するコールバック関数
   */
  on(callback?: () => void): void {
    const CurrentPosition = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${CurrentPosition}px`;
    document.body.style.right = '0px';
    document.body.style.left = '0px';
    if (callback) callback();
  },
  /**
   * on bodyを固定する関数
   * @param {callback} callback - bodyを固定を解除した後に実行するコールバック関数
   */
  off(callback?: () => void): void {
    const GetBodyPositionTop = parseInt(document.body.style.top) * -1;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.right = '';
    document.body.style.left = '';
    window.scrollTo(0, GetBodyPositionTop);
    if (callback) callback();
  },
};
