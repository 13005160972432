import MicroModal from 'micromodal';
import { scrollFix } from '../utilitys/ScrollFix';

(() => {
  const globalNavigationName = 'global-navigation';
  const globalNavigation = document.getElementById(globalNavigationName);
  const globalNavigationButton = document.getElementById('global-navigation-button');
  const ariaLabelList = {
    open: 'open global navigation',
    close: 'close global navigation',
  };
  const openClassName = 'is-open';

  const globalNavigationOpen = () => {
    scrollFix.on();
    globalNavigationButton?.setAttribute('aria-expanded', 'true');
    globalNavigationButton?.setAttribute('aria-label', ariaLabelList.close);
  };
  const globalNavigationClose = () => {
    scrollFix.off();
    globalNavigationButton?.setAttribute('aria-expanded', 'false');
    globalNavigationButton?.setAttribute('aria-label', ariaLabelList.open);
  };

  MicroModal.init({
    onShow: () => globalNavigationOpen(),
    onClose: () => globalNavigationClose(),
    openTrigger: 'data-global-navigation-trigger',
    closeTrigger: 'data-global-navigation-close',
    awaitCloseAnimation: true,
    disableFocus: true,
  });

  globalNavigationButton?.querySelector('span')?.addEventListener('click', (e) => {
    e.stopPropagation();
    const hasOpenClassName = globalNavigation?.classList.contains(openClassName);
    if (hasOpenClassName) {
      MicroModal.close(globalNavigationName);
    }
  });
})();
