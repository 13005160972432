import MicroModal from 'micromodal';
import { scrollFix } from '../utilitys/ScrollFix';

((): void => {
  MicroModal.init({
    onShow: () => scrollFix.on(),
    onClose: () => scrollFix.off(),
    openTrigger: 'data-modal-trigger',
    closeTrigger: 'data-modal-close',
    awaitCloseAnimation: true,
    disableFocus: true,
    // debugMode: true,
  });
})();
