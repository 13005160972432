import './ie';
import './@modules/SafariMediaQueryListPolyfill';
// import './@modules/SafariMediaQueryListPolyfill';
import 'what-input';
import './@modules/UserAgent';
import './@modules/SmoothScroll';
// import './@modules/SetViewportProperty';
// import './React/index';

//         modules
//---------------------------

import './@modules/Modal';
import './@modules/GlobalNavigation';

//src/variables.jsonを使いたい場合async functionを使用する。
// import { variables } from './utilitys/Variables';
// (async () => {
//   console.log(await variables);
// })();
