//safariとIEにはMediaQueryListのchangeイベントが無いので同様の動きをするポリフィル
//エラーが多いのでここのみ型チェックの対象外とする。
((): void => {
  function safariMediaQueryListPolyfill(): void {
    // MediaQueryList.prototype.addEventListener.ts
    if (typeof matchMedia !== 'undefined' && !matchMedia('all').addEventListener) {
      console.log('installing polyfill: MediaQueryList.prototype.addEventListener');

      const originalMatchMedia = matchMedia;
      // @ts-ignore
      self.matchMedia = function matchMedia(mediaQuery: string): MediaQueryList {
        const mql = originalMatchMedia(mediaQuery);
        // @ts-ignore
        mql.addEventListener = function (eventName: 'change', listener: (event: MediaQueryListEvent) => void): any {
          this.addListener(listener);
        };
        return mql;
      };
    }
  }

  safariMediaQueryListPolyfill();
})();
