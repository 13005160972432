import SweetScroll from 'sweet-scroll';
//https://github.com/tsuyoshiwada/sweet-scrolls

((): void => {
  const topButton = 'js-top-button';
  if (document.getElementById(topButton)) {
    new SweetScroll({
      trigger: `#${topButton}`,
    });
  }

  const linkElements = 'a[href^="#"]';
  if (document.querySelector(linkElements)) {
    // new SweetScroll({
    //   trigger: linkElements,
    // });
    const scroller = new SweetScroll({ trigger: linkElements });
    // const mediaQuery = matchMedia(`(min-width: ${Breakpoints.sm}px)`);
    // const handle = (): void => {
    //   if (!mediaQuery.matches) {
    scroller.update({ header: '#header' });
    //   } else {
    //     scroller.update({ header: '#sticky_header' });
    //   }
    // };
    // handle();
    // mediaQuery.addEventListener('change', () => handle());
  }
})();
