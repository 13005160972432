// import 'promise-polyfill/src/polyfill';
import '@babel/polyfill';
import 'picturefill';
import 'svgxuse';
import 'intersection-observer';
import Stickyfill from 'stickyfilljs';
import objectFitImages from 'object-fit-images';

window.addEventListener('DOMContentLoaded', () => {
  const images: NodeListOf<HTMLElement> = document.querySelectorAll('img');
  objectFitImages(images);

  const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.sticky');
  Stickyfill.add(elements);
});
